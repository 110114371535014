import { Provider } from 'react-redux';
import store from 'lib/store';
import { Helmet } from 'react-helmet';
import FlagProvider from '@unleash/proxy-client-react';
import { unleashClient } from 'config/unleash';
import AppContainer from 'components/AppContainer';
import React from 'react';
import { WindowProvider } from 'mangools-react-components/src';

const isStaging = process.env.NODE_ENV === 'staging';
const isProduction = process.env.NODE_ENV === 'production';

export const App = () => {
    return (
        <Provider store={store}>
            {(isStaging || isProduction) && (
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                    <meta httpEquiv="Content-Security-Policy" content="block-all-mixed-content" />
                    <meta httpEquiv="cache-control" content="max-age=0" />
                    <meta httpEquiv="cache-control" content="no-cache" />
                    <meta httpEquiv="expires" content="0" />
                    <meta httpEquiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
                    <meta httpEquiv="pragma" content="no-cache" />
                    <meta name="robots" content="noindex, nofollow" />
                    <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                    <meta name="description" content="SiteProfiler is an SEO website analysis tool giving you insights on website authority, backlinks, competitors and most popular content. Try it for free!" />
                    <meta name="theme-color" content="#b71071" />
                    <meta name="msapplication-navbutton-color" content="#b71071" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="apple-mobile-web-app-status-bar-style" content="#b71071" />
                    <meta name="google" content="notranslate" />
                    <title>SiteProfiler by Mangools: Domain Authority Checker</title>
                    <link rel="shortcut icon" href="/siteprofiler/favicon.ico" />
                </Helmet>
            )}
            <FlagProvider unleashClient={unleashClient}>
                <WindowProvider>
                    <AppContainer />
                </WindowProvider>
            </FlagProvider>
        </Provider>
    );
}